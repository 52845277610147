<template>
  <v-app class="not-found-wrap">
    <div
      class="not-found d-flex flex-column justify-center align-center flex-grow-1 px-4"
    >
      <h2 class="gradient-text">Forgot Password?</h2>
      <v-card
        tile
        class="px-7 mt-5 pb-5"
        width="480"
        :loading="loading"
        :disabled="loading"
      >
        <v-text-field
          placeholder=" "
          v-model="email"
          label="Username/Email"
          hint="We will send a reset password link to your email"
          :persistent-hint="true"
          required
          tabindex="1"
          class="my-1 mt-7"
          :error="errorText != ''"
          :error-messages="errorText"
        ></v-text-field>
        <div class="d-flex justify-end mt-3">
          <v-btn to="/" small tile class="primary mr-2">Cancel</v-btn>
          <v-btn
            :disabled="email.trim() === '' || errorText != ''"
            small
            tile
            class="primary"
            @click="submit"
            >Submit</v-btn
          >
        </div>
      </v-card>
    </div>
  </v-app>
</template>

<script>
import helpers from "@/helpers";
import store from "@/store";
import axios from "axios";

export default {
  data() {
    return { email: "", nonExistEmails: [], loading: false };
  },
  computed: {
    errorText() {
      const email = this.email.trim();
      if (!email.length) {
        return "";
      }
      if (
        !helpers.string.validateEmail(email) &&
        !helpers.string.usernameCriteriaMet(email)
      ) {
        return "Enter a valid username or email with minimum 5 characters";
      }
      if (this.nonExistEmails.includes(email)) {
        return (
          "We didn't find an account linked to this " +
          (helpers.string.validateEmail(email) ? "email" : "username")
        );
      }
      return "";
    }
  },
  methods: {
    submit() {
      const email = this.email.trim();
      this.loading = true;
      axios
        .post(`${process.env.VUE_APP_API_NOAUTH}/forgotPassword`, {
          email
        })
        .then(() => {
          store.app.showDialog(
            "Check your mail",
            "We have sent a reset password link to your email, which will help you to reset your account password",
            "warning"
          );
          this.$router.replace("/");
        })
        .catch(({ response }) => {
          if (response.status === 404) {
            this.nonExistEmails.push(email);
          } else if (response.status === 400) {
            store.app.showDialog(
              "Check your mail",
              "Already sent reset password link to this email several times, please try after few hours",
              "error"
            );
          } else {
            store.app.showAlert("Some error occured!");
          }
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>

<style lang="scss" scoped>
.not-found-wrap {
  background: none;
  overflow: hidden;
}
</style>
